import Swiper, { Navigation, Pagination } from 'swiper';
import SmoothScroll from 'smooth-scroll';
export default class ProjectSlider {
  constructor() {
    this.init();
  }

  init() {
    // const projectImageBoxes = document.querySelectorAll('.js-swiperFrame');
    // const swiperPagination = document.querySelectorAll('.js-swiperPagination');

    if (!document.querySelectorAll('.projectPage')) {
      return false;
    }
    const scroll = new SmoothScroll('a[href*="#"]', {
      speed: 300,
      speedAsDuration: false,
      updateURL: false,
    });

    const tagList = document.querySelectorAll('.project__tagListOne');
    const itemList = document.querySelectorAll('.project__itemListOne');
    const projects = document.querySelectorAll('.projectOneBox');

    for (let i = 0; i < tagList.length; i++) {
      tagList[i].addEventListener('click', function (e) {
        const target = e.currentTarget;
        // alert(target.dataset.type);
        const type = target.dataset.type;
        resetTag();
        // target.firstElementChild.classList.add('Act');
        target.classList.add('Act');
        if (type == 'js-SortAll') {
          viewAll();
        } else {
          viewSelect(type);
        }
      });
    }

    function resetTag() {
      for (let i = 0; i < tagList.length; i++) {
        // tagList[i].firstElementChild.classList.remove('Act');
        tagList[i].classList.remove('Act');
      }
    }

    function viewSelect(type) {
      for (let i = 0; i < itemList.length; i++) {
        itemList[i].classList.add('Hide');
      }
      for (let j = 0; j < projects.length; j++) {
        projects[j].classList.add('Hide');
      }
      const view = document.querySelectorAll('.' + type);
      setTimeout(() => {
        for (let i = 0; i < view.length; i++) {
          view[i].classList.remove('Hide');
        }
      }, 5);
    }

    function viewAll() {
      for (let i = 0; i < itemList.length; i++) {
        itemList[i].classList.add('Hide');
      }
      for (let j = 0; j < projects.length; j++) {
        projects[j].classList.add('Hide');
      }
      setTimeout(() => {
        for (let i = 0; i < itemList.length; i++) {
          // itemList[i].classList.add('View');
          itemList[i].classList.remove('Hide');
        }
        for (let j = 0; j < projects.length; j++) {
          // projects[j].classList.add('View');
          projects[j].classList.remove('Hide');
        }
      }, 5);
    }

    // if (!projectImageBoxes) {
    //   return false;
    // }
    // for (let i = 0; i < projectImageBoxes.length; i++) {
    //   let swiperCLass = 'swiper_' + i;
    //   let paginationCLass = 'pagination_' + i;
    //   projectImageBoxes[i].classList.add(swiperCLass);
    //   swiperPagination[i].classList.add(paginationCLass);
    //   const swiper = new Swiper('.' + swiperCLass, {
    //     modules: [Navigation, Pagination],
    //     centeredSlides: true,
    //     loop: true,
    //     pagination: {
    //       el: '.' + paginationCLass,
    //       type: 'bullets',
    //       clickable: true,
    //     },
    //   });
    // }
  }
}
